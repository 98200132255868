import { Grid } from '@mui/material';
import { useState } from 'react';

import { type ContentObjectOption, ContentObjectOptions } from '@openx/types';

import { type OptionsConfig, type UseContentObjectOptionsConfig, useTargetingContext } from '../../../utils';
import { DrawerTopBox } from '../../shared';
import { type ContentObjectOptionsProps, type ContentObjectTopBoxProps } from '../types';
import { isEpisode, isFreeText, isDropdown, isStatic, isOptionItem, isHierarchy } from '../utils';

import {
  FreeTextOptions,
  StandardDropdown,
  StaticOptions,
  TypeOptionDropdown,
  EpisodeOptions,
  HierarchyOptions,
} from './OptionDropdowns';

export const ContentObjectTopBox = (props: ContentObjectTopBoxProps) => {
  const { useContentObjectOptionsConfig } = useTargetingContext();

  if (!useContentObjectOptionsConfig) {
    throw new Error('useContentObjectOptionsConfig is not provided');
  }

  return <ContentObjectTopBoxRender {...props} useContentObjectOptionsConfig={useContentObjectOptionsConfig} />;
};

export const ContentObjectOptionsRender = ({
  type,
  optionsConfig,
  formState,
  dispatch,
}: ContentObjectOptionsProps & { optionsConfig: OptionsConfig } & { loading: boolean; options: any }) => {
  const { useOptionFetch, ...restOptionsConfig } = optionsConfig;

  const optionItem = restOptionsConfig[type];

  if (isDropdown(type))
    return <StandardDropdown type={type} useOptionFetch={useOptionFetch} formState={formState} dispatch={dispatch} />;

  if (isStatic(type) && isOptionItem(optionItem)) {
    const { loading = false, options } = optionItem;
    return <StaticOptions type={type} loading={loading} dispatch={dispatch} options={options} />;
  }

  if (isFreeText(type)) return <FreeTextOptions type={type} formState={formState} dispatch={dispatch} />;

  if (isEpisode(type)) return <EpisodeOptions type={type} formState={formState} dispatch={dispatch} />;

  if (isHierarchy(type))
    return <HierarchyOptions type={type} formState={formState} useOptionFetch={useOptionFetch} dispatch={dispatch} />;

  return null;
};

const getAlign = (type: ContentObjectOption) => {
  if (isDropdown(type)) return 'flex-end';
  if (isEpisode(type)) return 'flex-start';
};

const ContentObjectTopBoxRender = ({
  formState,
  dispatch,
  useContentObjectOptionsConfig,
}: ContentObjectTopBoxProps & {
  useContentObjectOptionsConfig: UseContentObjectOptionsConfig;
}) => {
  const [type, setType] = useState<ContentObjectOption>(ContentObjectOptions.GENRE);

  const optionsConfig = useContentObjectOptionsConfig();
  const enabledTypes = Object.keys(optionsConfig).filter(key => optionsConfig[key] !== undefined);

  const selectedOption = optionsConfig[type];
  const alignItems = getAlign(type);

  const { loading, options } = selectedOption;
  return (
    <DrawerTopBox>
      <Grid container spacing={1} alignItems={alignItems}>
        <Grid item xs={3}>
          <TypeOptionDropdown enabledTypes={enabledTypes} value={type} onChange={setType} />
        </Grid>
        <ContentObjectOptionsRender
          type={type}
          loading={loading}
          options={options}
          optionsConfig={optionsConfig}
          formState={formState}
          dispatch={dispatch}
        />
      </Grid>
    </DrawerTopBox>
  );
};
