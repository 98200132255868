import { Grid } from '@mui/material';
import { capitalize } from 'lodash';
import { useState, useCallback, type HTMLAttributes, type ChangeEvent, useEffect, useMemo } from 'react';

import { AutoCompleteVirtualize } from '@openx/components/core';
import {
  type AllowBlock,
  AllowBlockType,
  type ContentObjectOptions,
  type ContentObjectAction,
  type ContentObjectOption,
  type ContentObjectProps,
} from '@openx/types';

import type { UseOptionFetch } from '../../../../../utils/context/TargetingHookTypes';
import { getOptionName, inputPlaceholder } from '../../../constants';

import { ClearAllOptionDropdownItem } from './ClearAllOptionsDropdownItem';
import { HierarchyOptionDropdownItem } from './HierarchyOptionDropdownItem';
import { prepareItemsForAction, prepareItemsForApi, prepareOptionsForHierarchy, prepareOptionsKeys } from './utils';

type HierarchyOptionsProps = {
  type: ContentObjectOption;
  formState:
    | Pick<ContentObjectProps, typeof ContentObjectOptions.GENRE>
    | Pick<ContentObjectProps, typeof ContentObjectOptions.CONTENT_RATING>;
  useOptionFetch: UseOptionFetch;
  dispatch: (action: ContentObjectAction) => void;
};

export const HierarchyOptions = ({ type, formState, useOptionFetch, dispatch }: HierarchyOptionsProps) => {
  const [search, setSearch] = useState('');

  const selectedItems: AllowBlock = formState[type];
  const areSelectedItemsEmpty = !selectedItems.allow.length && !selectedItems.block.length;

  const hasAllowItems = selectedItems.allow.length ? AllowBlockType.ALLOW : AllowBlockType.BLOCK;

  const [rule, setRule] = useState<AllowBlockType | null>(areSelectedItemsEmpty ? null : hasAllowItems);

  const { loading, options } = useOptionFetch(type);

  const { leveledOptionsMap } = useMemo(() => prepareOptionsForHierarchy(options), [options]);

  const onClose = useCallback(() => {
    setSearch('');
  }, [setSearch]);

  const onSetSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const handleAction = useCallback(
    (optionId: string, actionType: 'allow' | 'block', canUnselect: boolean) => {
      const { allow, block } = prepareItemsForAction({
        action: actionType,
        canUnselect,
        optionId,
        optionsMap: leveledOptionsMap,
        selectedItems,
      });

      if (rule == null) {
        setRule(actionType === 'allow' ? AllowBlockType.ALLOW : AllowBlockType.BLOCK);
      }

      dispatch({
        type,
        values: {
          ...selectedItems,
          allow,
          apiAllow: prepareItemsForApi(allow, leveledOptionsMap),
          apiBlock: prepareItemsForApi(block, leveledOptionsMap),
          block,
        },
      });
    },
    [dispatch, leveledOptionsMap, rule, selectedItems, type]
  );

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, optionId: string) => (
      <li {...props} key={optionId} data-test={'content-object-hierarchy-item'}>
        {optionId === 'clear_all' ? (
          <ClearAllOptionDropdownItem
            onClear={() => {
              setRule(null);
              dispatch({
                type,
                values: { allow: [], block: [] },
              });
            }}
          />
        ) : (
          <HierarchyOptionDropdownItem
            key={optionId}
            optionsMap={leveledOptionsMap}
            optionId={optionId}
            rule={rule}
            search={search}
            selectedItems={selectedItems}
            handleAllowAction={(optionId, canUnselect) => handleAction(optionId, 'allow', canUnselect)}
            handleBlockAction={(optionId, canUnselect) => handleAction(optionId, 'block', canUnselect)}
          />
        )}
      </li>
    ),
    [dispatch, handleAction, leveledOptionsMap, rule, search, selectedItems, type]
  );

  useEffect(() => {
    const shouldClearRule = !selectedItems.allow.length && !selectedItems.block.length && rule !== null;

    if (shouldClearRule) {
      setRule(prevRule => (prevRule !== null ? null : prevRule));
    }
  }, [selectedItems, rule, setRule]);

  useEffect(() => {
    setRule(areSelectedItemsEmpty ? null : hasAllowItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Grid item xs={9}>
      <AutoCompleteVirtualize
        textFieldProps={{
          label: getOptionName(type),
          placeholder: inputPlaceholder(type),
        }}
        renderOption={renderOption}
        getOptionLabel={option => capitalize(leveledOptionsMap[option]?.name)}
        disableClearable
        options={prepareOptionsKeys(leveledOptionsMap)}
        onClose={onClose}
        loading={loading}
        disableCloseOnSelect
        onDebounceChange={onSetSearch}
        inputValue={search}
        freeSolo
        fullWidth
        forcePopupIcon
        data-test="hierarchy-options-dropdown"
      />
    </Grid>
  );
};
