import { DealState } from './deal';
import { BrandFloorFormParams } from './floorrule';
import type { Targeting, UrlTargeting, TargetingState } from './targeting';

export interface Package {
  id: string;
  uid: string;
  account_id: string;
  account_uid: string;
  domains: string | null;
  logo: string | null;
  name: string;
  notes: string;
  type: string;
  type_full: string;
  urls: Record<string, boolean> | string[] | null;
  locked_internal: boolean;
  rate_card_cpm: string | null;
  discounted_cpm: string | null;
  private_market: PrivateMarket | null;
  targeting: Targeting;
  is_curated?: boolean;
}

export type PackageListItem = {
  account_id: string;
  account_uid: string;
  discounted_cpm: string | null;
  id: string;
  name: string;
  rate_card_cpm: string | null;
  type: string;
  type_full: string;
  uid: string;
  device_type?: string | null;
  distribution_channel?: string | null;
  locked_internal: boolean;
  targeting?: Targeting;
  is_curated?: boolean;
};

export interface PrivateMarket {
  default_discounted_cpm: string | null;
  default_rate_card_cpm: string | null;
  discoverable: '0' | '1';
}

export interface PackageWithTargeting extends Package {
  url_targeting?: UrlTargeting | null;
  targeting: Targeting;
}

export interface PackageFormState
  extends Omit<
    Package,
    'private_market' | 'discounted_cpm' | 'urls' | 'uid' | 'type' | 'type_full' | 'account_id' | 'id' | 'targeting'
  > {
  domains: string;
  logo: string;
  urls: string[];
  rate_card_cpm: string;
  private_market: {
    default_discounted_cpm: string;
    default_rate_card_cpm: string;
    discoverable: boolean;
  };
  targeting: TargetingState;
}

export type PackageFormParams = {
  deal?: DealState;
} & BrandFloorFormParams;

export enum PackageStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum PackageFields {
  ACCOUNT_ID = 'account_id',
  ACCOUNT_UID = 'account_uid',
  NAME = 'name',
  DESCRIPTION = 'notes',
  LOGO = 'logo',
  DOMAINS = 'domains',
  URLS = 'urls',
  RATE = 'rate_card_cpm',
  PRIVATE_MARKET = 'private_market',
  TARGETING = 'targeting',
  LOCKED_INTERNAL = 'locked_internal',
  IS_CURATED = 'is_curated',
}

export enum PrivateMarketFields {
  DEFAULT_RATE = 'default_rate_card_cpm',
  DISCOUNTED_RATE = 'default_discounted_cpm',
  DISCOVERABLE = 'discoverable',
}

type EditModePayload = Partial<PackageListItem> | null;
export type PackageCloneData = Pick<PackageListItem, 'id' | 'uid' | 'name'>;

export type ClonePackagePayload = {
  packages: PackageCloneData[];
  editModePayload?: EditModePayload;
};
