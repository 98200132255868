export enum ACL_TYPE {
  ADUNIT_AD_TAG_WRITE = 'adunit.ad_tag.write',
  ADUNIT_CREATE = 'adunit.create',
  ADUNIT_DELETE = 'adunit.delete',
  ADUNIT_READ = 'adunit.read',
  ADUNIT_UPDATE = 'adunit.update',

  ADVERTISER_CREATE = 'advertiser.create',
  ADVERTISER_EXCHANGE_CREDIT_READ = 'advertiser.exchange.credit.read',
  ADVERTISER_EXCHANGE_DEAL_LINEITEM_WRITE = 'advertiser.exchange.deal_lineitem.write',
  ADVERTISER_EXCHANGE_QPS_TARGET_WRITE = 'advertiser.exchange.qps_target.write',
  ADVERTISER_EXCHANGE_SITE_ID_TARGETING_READ = 'advertiser.exchange.site_id_targeting.read',
  ADVERTISER_READ = 'advertiser.read',
  ADVERTISER_UPDATE = 'advertiser.update',

  AD_QUALITY_FEATURE_ENABLED = 'feature.ad_quality.enabled',

  APP_CATEGORY_CREATE = 'appcategory.create',
  APP_CATEGORY_DELETE = 'appcategory.delete',
  APP_CATEGORY_READ = 'appcategory.read',
  APP_CATEGORY_UPDATE = 'appcategory.update',

  BRAND_CREATE = 'brand.create',
  BRAND_DELETE = 'brand.delete',
  BRAND_READ = 'brand.read',
  BRAND_UPDATE = 'brand.update',

  BUYER_CREATE = 'buyer.create',
  BUYER_DELETE = 'buyer.delete',
  BUYER_READ = 'buyer.read',
  BUYER_UPDATE = 'buyer.update',

  DEAL_CREATE = 'pmp.create',
  DEAL_DELETE = 'pmp.delete',
  DEAL_ENABLE_DV360_INVENTORY_PACKAGE_WRITE = 'pmp.enable_dv360_inventory_package.write',
  DEAL_FORECASTING_USER = 'feature.deal_forecasting.enabled',
  DEAL_RBO_CONFIG_READ = 'pmp.rbo_config.read',
  DEAL_RBO_CONFIG_WRITE = 'pmp.rbo_config.write',
  DEAL_IS_PUB_VISIBLE_READ = 'pmp.is_pub_visible.read',
  DEAL_IS_PUB_VISIBLE_WRITE = 'pmp.is_pub_visible.write',
  DEAL_READ = 'pmp.read',
  DEAL_THIRD_PARTY_REVENUE_CREATE = 'pmp.third_party_revenue.write',
  DEAL_UPDATE = 'pmp.update',

  DMP_SOURCES_READ = 'dmp.read',

  DOMAIN_CREATE = 'domain.create',
  DOMAIN_DELETE = 'domain.delete',
  DOMAIN_READ = 'domain.read',
  DOMAIN_UPDATE = 'domain.update',

  DV360_INVENTORY_LIST_READ = 'dv360_inventory_list.read',
  DV360_INVENTORY_LIST_WRITE = 'dv360_inventory_list.write',

  EXT_INVENTORY_MAP_READ = 'internal.ext_inventory_map.read',
  EXT_INVENTORY_MAP_WRITE = 'internal.ext_inventory_map.write',

  FEATURE_PACKAGE_CURATOR = 'feature.package_curator.enabled',

  FEE_CREATE = 'fee.create',
  FEE_DELETE = 'fee.delete',
  FEE_READ = 'fee.read',
  FEE_UPDATE = 'fee.update',

  FLOORRULE_CREATE = 'floorrule.create',
  FLOORRULE_DELETE = 'floorrule.delete',
  FLOORRULE_READ = 'floorrule.read',
  FLOORRULE_UPDATE = 'floorrule.update',

  INTERNAL_FIELD_READ = 'internal.field.read',
  INTERNAL_FIELD_WRITE = 'internal.field.write',

  LINEITEM_CREATE = 'lineitem.create',
  LINEITEM_DELETE = 'lineitem.delete',
  LINEITEM_READ = 'lineitem.read',
  LINEITEM_UPDATE = 'lineitem.update',

  MARKET_OPERATOR_SEARCH = 'ssp.market_operator_instance.enabled',

  METACATEGORY_CREATE = 'metacategory.create',
  METACATEGORY_DELETE = 'metacategory.delete',
  METACATEGORY_READ = 'metacategory.read',
  METACATEGORY_UPDATE = 'metacategory.update',

  NETWORK_CREATE = 'network.create',
  NETWORK_READ = 'network.read',
  NETWORK_UPDATE = 'network.update',

  OPTIMIZATION_CREATE = 'optimization.create',
  OPTIMIZATION_DELETE = 'optimization.delete',
  OPTIMIZATION_READ = 'optimization.read',
  OPTIMIZATION_SALES_CHANNELS_WRITE = 'optimization.sales_channels.write',
  OPTIMIZATION_UPDATE = 'optimization.update',

  ORDER_CREATE = 'order.create',
  ORDER_READ = 'order.read',
  ORDER_UPDATE = 'order.update',
  ORDER_DELETE = 'order.delete',

  PACKAGE_CREATE = 'package.create',
  PACKAGE_DELETE = 'package.delete',
  PACKAGE_READ = 'package.read',
  PACKAGE_UPDATE = 'package.update',

  PARTNER_READ = 'partner.read',

  POST_AUCTION_DISCOUNT_CREATE = 'postauctiondiscount.create',
  POST_AUCTION_DISCOUNT_DELETE = 'postauctiondiscount.delete',
  POST_AUCTION_DISCOUNT_READ = 'postauctiondiscount.read',
  POST_AUCTION_DISCOUNT_UPDATE = 'postauctiondiscount.update',

  PUBLISHER_CREATE = 'publisher.create',
  PUBLISHER_DELETE = 'publisher.delete',
  PUBLISHER_READ = 'publisher.read',
  PUBLISHER_UPDATE = 'publisher.update',

  ROOT_WORKAS_ANYINSTANCE = 'root.workas.anyinstance',

  SEAT_CREATE = 'seat.create',
  SEAT_DELETE = 'seat.delete',
  SEAT_READ = 'seat.read',
  SEAT_UPDATE = 'seat.update',

  SITE_CREATE = 'site.create',
  SITE_DELETE = 'site.delete',
  SITE_MAX_NUM_OF_ALLOWED_BIDS_READ = 'site.max_num_of_allowed_bids.read',
  SITE_READ = 'site.read',
  SITE_UPDATE = 'site.update',

  USER_CREATE = 'user.create',
  USER_DELETE = 'user.delete',
  USER_READ = 'user.read',
  USER_ROLE_WRITE = 'user.role.write',
  USER_SELF_ROLE_WRITE = 'user.self.role.write',
  USER_SELF_UPDATE = 'user.self.update',
  USER_UPDATE = 'user.update',
}
