import { Account } from './account';
import { ValidationError } from './api';
import { WithRequired } from './helpers';
import { ListState } from './list';
import { Audience, TargetingState } from './targeting';
import { i18nTranslate } from './translations';

export const ThirdPartyRevenueMethods = {
  CPM: 'CPM',
  PoM: 'PoM',
} as const;

export type ThirdPartyRevenueMethodValues = (typeof ThirdPartyRevenueMethods)[keyof typeof ThirdPartyRevenueMethods];

export type ThirdPartyRevenueMethod = typeof ThirdPartyRevenueMethods;

export type ThirdPartyRevenueCPM = {
  partner: string;
  cpm_cap?: string | null;
  revenue_method: ThirdPartyRevenueMethod['CPM'];
};

export type ThirdPartyRevenuePoM = {
  partner: string;
  share?: string | null;
  revenue_method: ThirdPartyRevenueMethod['PoM'];
};

export type ThirdPartyRevenue = ThirdPartyRevenueCPM | ThirdPartyRevenuePoM;

export type RboConfig = {
  daily_goal_target: string | number;
  format: string;
  goal?: string | null;
  industry?: string | null;
  threshold?: string | null;
  tactic?: string | null;
} | null;

export interface DealCreate {
  account_uid: string;
  name?: string | null;
  status: string;
  pmp_deal_type: PmpDealType;
  currency: string;
  deal_price: string;
  // use_market_floor           API Variance
  deal_source: string;
  // deal_participants          API Variance
  package_uid: string;
  deal_supplements: SupplementalAdQuality | null;
  rbo_config?: RboConfig;
  third_party_revenue?: {
    partner: string;
    share: number | null;
    cpm_cap: number | null;
    revenue_method: 'CPM' | 'PoM';
  } | null;
  third_party_fees_config?: null | [ThirdPartyFeeConfig];
  account_executive?: string | null;
  account_manager?: string | null;
  end_date?: string | null;
  start_date?: string;
}

export type ThirdPartyFeesConfig = {
  partner_id: string;
  gross_cpm_cap?: string | null;
  gross_share?: string | null;
  revenue_method: ThirdPartyRevenueMethod['CPM'] | ThirdPartyRevenueMethod['PoM'];
  platform_share?: string | null;
};

export type ThirdPartyFeeConfigCPM = {
  partner_id: string;
  gross_cpm_cap: string | null;
  platform_share: string;
  revenue_method: 'CPM';
  platform_partner_id: string;
};

export type ThirdPartyFeeConfigPoM = {
  partner_id: string;
  gross_share?: string | null;
  gross_cpm_cap?: string | null;
  platform_share: string;
  revenue_method: 'PoM';
  platform_partner_id: string;
};

export type ThirdPartyFeeConfigU = ThirdPartyFeeConfigCPM | ThirdPartyFeeConfigPoM;

export type ThirdPartyFeeConfig = {
  partner_id: string;
  gross_share?: string | null;
  gross_cpm_cap?: string | null;
  platform_share?: string | null;
  revenue_method: ThirdPartyRevenueMethod['CPM'] | ThirdPartyRevenueMethod['PoM'];
  platform_partner_id?: string;
};

export type ThirdPartyFee = {
  share?: string | null;
  cpm_cap?: string | null;
  fee_type: string;
  partner_id: string;
  revenue_method: 'PoM' | 'CPM';
};

export type ThirdPartyPartnersOption = { id: number; name: string; default_share: string | null };

export interface DealBase extends DealCreate {
  id: string;
  uid: string;
  type_full: string;
  type: 'deal';
  account_uid: string;
  start_date?: string;
  deal_id?: string | null;
  pmp_deal_type: PmpDealType;
  deal_source: string;
  third_party_fees?: ThirdPartyFee[] | null;
  open_auction_access: string | null;
  lifetime_impression_goal: string | number | null;
  is_pub_visible: boolean;
  enable_dv360_inventory_package?: boolean;
  revision?: number;
  modified_date?: string;
  created_date?: string;
  expected_ad_category: string | null;
}

export interface Deal extends DealBase {
  open_auction_access: string;
  use_market_floor: boolean | string | null;
  deal_participants: DealParticipant[];
}

export type EnrichedDeal = DealBase & {
  used_by_postauctiondiscount: boolean;
};

// Deal Form
export interface DealState extends Deal {
  dv360_inventory_package?: DV360InventoryPackage | null;
  synchronized_with?: SynchronizedWith | null;
  generated_deal_id?: string | null;
  custom_deal_id?: string | null;
  generated_deal_id_prefix?: string | null;
  deal_id_type_radio?: string | null;
  targeting_section_switch?: TargetingSectionDealFormSwitch;
}

export type ListDealBase = Pick<
  Deal,
  | 'id'
  | 'uid'
  | 'name'
  | 'deal_id'
  | 'status'
  | 'start_date'
  | 'end_date'
  | 'modified_date'
  | 'created_date'
  | 'deal_participants'
> & {
  synchronized_with?: SynchronizedWith | null;
};

export type ListDeal = ListDealBase & Pick<Deal, 'currency' | 'deal_price' | 'package_uid' | 'pmp_deal_type'>;

export type OAListDeal = ListDealBase & OADealFields;
export interface OADealState extends DealState {
  targeting: TargetingState;
}

export interface DealFormState<T extends Partial<Deal> = DealState> {
  loading: boolean;
  error?: ValidationError<T> | Error;
  currentDeal?: T;
}

type DealApiBase = {
  use_market_floor: '0' | '1' | null;
  deal_participants: DealParticipantApi[];
};

export interface DealApiCreate extends DealCreate, DealApiBase {}

export interface DealApi
  extends DealApiBase,
    WithRequired<
      DealBase,
      | 'modified_date'
      | 'account_executive'
      | 'account_manager'
      | 'revision'
      | 'end_date'
      | 'enable_dv360_inventory_package'
    > {
  instance_uid: string;
  created_date: string;
  notes: string;
  apply_exchange_fee: string | null;
  has_multiple_publishers: string | null;
  price_config: string | null;
  bid_reuse_disabled: number;
  deal_exclusion_rate: string | null;
  account_id: string;
  package_id: string;
  package_name: string;
  v: number;
  deleted: number;
  type: 'deal';
  expected_ad_category: string | null;
}

export type DealApiSubmit = DealApiCreate & Partial<DealApi>;

// TODO: UIG-540 Fix DealParticipantApi is wrong type and uses of type casting
export interface DealParticipantApi {
  demand_partner: string;
  openx_buyer_ids?: string[];
  brand_ids?: Record<string, unknown>;
  buyer_id?: string | null;
  buyer_ids?: Record<string, unknown>;
  main_buyer_id: string | null;
  hasThirdPartyOptions?: boolean;
}

export interface DealParticipant {
  demand_partner: string | null;
  openx_buyer_ids: string[];
  brand_ids: string[];
  buyer_ids: string[];
  main_buyer_id: string | null;
  buyer_id: string | null;
}

interface SupplementalAdQualityFilter {
  op: 'allow' | 'allow_all' | null;
  ids: string[] | Record<string, boolean>;
}

export interface SupplementalAdQuality {
  allow_unbranded_rtb_bids?: '0' | '1';
  lean_filters: {
    disable_total_requests_filter: '0' | '1';
    disable_pixel_buckets: '0' | '1';
  };
  brand_labels: SupplementalAdQualityFilter | null;
  brands: SupplementalAdQualityFilter | null;
  content_attributes: SupplementalAdQualityFilter | null;
  creative_types: SupplementalAdQualityFilter | null;
  languages: SupplementalAdQualityFilter | null;
}

export enum PmpDealType {
  PreferredDeal = '1',
  PrivateAuction = '2',
  WithinOpenAuction = '3',
  Guaranteed = '4',
}

export enum TargetingSectionDealFormSwitch {
  UNIQUE_TARGETING = 0,
  EXISTING_PACKAGE = 1,
  CURATED_PACKAGE = 2,
}

export enum OnThelist {
  Active = 'Active',
  Paused = 'Paused',
}

export interface DV360InventoryPackage {
  description: string;
  on_the_list: OnThelist;
}

export interface SynchronizedWith {
  dv360_inventory?: {
    inventory_package_status: OnThelist;
    inventory_package_name: string;
  } | null;
  dv360seller?: DV360Seller | null;
  ttd_proposal_id?: string | number | null;
}

export type DV360Seller = {
  order_name?: string | number | null;
  reject_reason?: string | null;
  failure_reason?: DV360FailureReason | null;
  reject_date?: string | null;
  failure_date?: string | null;
};

export const DV360FailureReasons = {
  ORDER_CANCELLED: 'order_cancelled',
} as const;

export type DV360FailureReason = (typeof DV360FailureReasons)[keyof typeof DV360FailureReasons];

export const SynchronizationStatuses = {
  FAILED: 2,
  NONE: -1,
  REJECTED: 1,
  SYNCED: 0,
} as const;

export type SynchronizationStatus = (typeof SynchronizationStatuses)[keyof typeof SynchronizationStatuses];

export interface MultipartnerDeal extends DealState {
  partner_account_uids: Account[];
  deal_shadow_list: DealShadow[];
}

export interface DealShadow extends Pick<DealState, 'name' | 'deal_price' | 'status' | 'currency'> {
  account_uid: string;
}

export type OADealFields = {
  _package?: {
    audience_id: string | null;
    audience: Audience | null;
  };
  account?: {
    name: string;
  } | null;
  exchange_uid?: string | null;
};

export interface DealOaApi extends DealApi {
  _package?: {
    audience_id?: string | null;
    audience?: Audience | null;
  };
  account?: {
    name: string;
  } | null;
  exchange_uid: string;
}

export interface DealShadowApi extends Partial<Pick<DealApi, 'name' | 'deal_price' | 'account_uid' | 'type_full'>> {
  multipartner_deal_id?: string;
}

export interface MultipartnerDealApi extends DealApi {
  partner_account_uids: string[];
  deal_shadow_list: DealShadowApi[];
}

export const MultipartnerTypeFull = 'deal.multipartner';
export const ShadowDealTypeFull = 'deal.shadow';

export interface DealTroubleshoot {
  timestamp: string;
  stats: {
    bid_requests: number;
    bid_responses: number;
    impressions: number;
  };
}

export const OaDealStatus = {
  ACTIVE: 'Active',
  ERROR: 'Error',
  EXPIRED: 'Expired',
  PAUSED: 'Paused',
  PUBLISHING: 'Publishing',
  READY_FOR_ACTIVATION: 'ReadyForActivation',
} as const;
// eslint-disable-next-line no-redeclare
export type OaDealStatus = (typeof OaDealStatus)[keyof typeof OaDealStatus];

export function getOaDealStatus(t: i18nTranslate) {
  return {
    Active: t('Active'),
    Error: t('Error'),
    Expired: t('Expired'),
    Paused: t('Paused'),
    Publishing: t('Publishing'),
  };
}

export enum DealStatus {
  Active = 'Active',
  Paused = 'Paused',
  Expired = 'Expired',
  GoalReached = 'Goal Reached',
}

export const dealStatusForm = {
  [DealStatus.Active]: 'active',
  [DealStatus.Expired]: 'inactive',
  [DealStatus.GoalReached]: 'goalreached',
  [DealStatus.Paused]: 'warning',
};

export function getDealStatus(t: i18nTranslate) {
  return {
    Active: t('Active'),
    Error: t('Error'),
    Expired: t('Expired'),
    GoalReached: t('Goal Reached'),
    Paused: t('Paused'),
  };
}

export type DealsListFiltersValue = {
  phrase: string;
  status?: string;
  pmp_deal_type?: string;
  package_uid?: string;
};

export type DealsListState<T extends Partial<Deal> = ListDeal> = ListState<T, DealsListFiltersValue>;

export const DEAL_DETAILS_TABS = {
  COMMENTS: 'comments',
  DETAILS: 'details',
  HISTORY: 'history',
  TROUBLESHOOT: 'troubleshoot',
} as const;

export type DealDetailsTabs = (typeof DEAL_DETAILS_TABS)[keyof typeof DEAL_DETAILS_TABS];

export enum ThirdPartyRevenueField {
  PARTNER = 'partner',
  SHARE = 'share',
  CPM_CAP = 'cpm_cap',
  REVENUE_METHOD = 'revenue_method',
}

export enum DealFormField {
  ACCOUNT_EXECUTIVE = 'account_executive',
  ACCOUNT_MANAGER = 'account_manager',
  ACCOUNT_UID = 'account_uid',
  CURRENCY = 'currency',
  CUSTOM_DEAL_ID = 'custom_deal_id',
  RBO_CONFIG = 'rbo_config',
  DEAL_ID = 'deal_id',
  DEAL_ID_TYPE_RADIO = 'deal_id_type_radio',
  DEAL_PARTICIPANTS = 'deal_participants',
  DEAL_PRICE = 'deal_price',
  DEAL_SHADOW_LIST = 'deal_shadow_list',
  DEAL_SOURCE = 'deal_source',
  DEAL_SUPPLEMENTS = 'deal_supplements',
  END_DATE = 'end_date',
  ENABLE_DV360_INVENTORY_PACKAGE = 'enable_dv360_inventory_package',
  GENERATED_DEAL_ID_PREFIX = 'generated_deal_id_prefix',
  IMPRESSION_GOAL = 'lifetime_impression_goal',
  IS_PUB_VISIBLE = 'is_pub_visible',
  NAME = 'name',
  OPEN_AUCTION_ACCESS = 'open_auction_access',
  PACKAGE_UID = 'package_uid',
  PARTNER_IDS = 'partner_account_uids',
  PMP_DEAL_TYPE = 'pmp_deal_type',
  PREVIEW_DEAL_ID = 'preview_deal_id',
  START_DATE = 'start_date',
  STATUS = 'status',
  USE_MARKET_FLOOR = 'use_market_floor',
  TARGETING = 'targeting',
  EXPECTED_AD_CATEGORY = 'expected_ad_category',
}

export const NewDealFormField = {
  ACCOUNT_EXECUTIVE: 'account_executive',
  ACCOUNT_MANAGER: 'account_manager',
  ACCOUNT_UID: 'account_uid',
  CURRENCY: 'currency',
  CUSTOM_DEAL_ID: 'custom_deal_id',
  DEAL_ID: 'deal_id',
  DEAL_ID_TYPE_RADIO: 'deal_id_type_radio',
  DEAL_PARTICIPANTS: 'deal_participants',
  DEAL_PRICE: 'deal_price',
  DEAL_SHADOW_LIST: 'deal_shadow_list',
  DEAL_SOURCE: 'deal_source',
  DEAL_SUPPLEMENTS: 'deal_supplements',
  ENABLE_DV360_INVENTORY_PACKAGE: 'enable_dv360_inventory_package',
  END_DATE: 'end_date',
  EXPECTED_AD_CATEGORY: 'expected_ad_category',
  GENERATED_DEAL_ID_PREFIX: 'generated_deal_id_prefix',
  IMPRESSION_GOAL: 'lifetime_impression_goal',
  IS_PUB_VISIBLE: 'is_pub_visible',
  NAME: 'name',
  OPEN_AUCTION_ACCESS: 'open_auction_access',
  PACKAGE_UID: 'package_uid',
  PARTNER_IDS: 'partner_account_uids',
  PMP_DEAL_TYPE: 'pmp_deal_type',
  PREVIEW_DEAL_ID: 'preview_deal_id',
  START_DATE: 'start_date',
  STATUS: 'status',
  TARGETING: 'targeting',
  USE_MARKET_FLOOR: 'use_market_floor',
} as const;

export const RboConfigFields = {
  DAILY_GOAL_TARGET: 'daily_goal_target',
  FORMAT: 'format',
  GOAL: 'goal',
  INDUSTRY: 'industry',
  TACTIC: 'tactic',
  THRESHOLD: 'threshold',
} as const;

export type TRboConfigField = (typeof RboConfigFields)[keyof typeof RboConfigFields];

export interface DealFormData
  extends Partial<
    Omit<
      DealState,
      'account_uid' | 'pmp_deal_type' | 'deal_source' | 'start_date' | 'deal_participants' | 'package_uid' | 'currency'
    >
  > {
  account_uid: string | null;
  pmp_deal_type: PmpDealType | null;
  deal_source: string | null;
  start_date?: string | null;
  deal_participants: DealParticipant[];
  package_uid: string | null;
  currency: string | null;
  deal_id_type?: string | null;
  custom_deal_id?: string | null;
  generated_deal_id_prefix?: string | null;
}

export interface OADealFormData extends DealFormData {
  targeting: TargetingState;
}
